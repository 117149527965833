import React, { useState, useEffect, useRef } from 'react'
import Placeholder from 'react-bootstrap/Placeholder'
import './design'
import './scripts'
import BANNER from './components/banner'
import BODY from './components/body'
function App() {
  const [language, setLanguage] = useState("EN")
  const [isShisha, setIsShisha] = useState(true)
  const [currency, setCurrency] = useState(["AED", "درهم"])
  const [direction, setDirection] = useState("ltr")
  const [activeTab, setActiveTab] = useState()
  const [filteredSubCategories, setFilteredSubCategories] = useState([])
  // eslint-disable-next-line
  const [generation, setGeneration] = useState('48')
  const [loadingCategories, setLoadingCategories] = useState(true)
  const dataRefCat = useRef([])
  const [loadingSubCategories, setLoadingSubCategories] = useState(true)
  const dataRefSubCat = useRef([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const dataRefProducts = useRef([])
  const dataRefProductsAr = useRef([])

  function handleLanguage(lang) {
    setLanguage(lang)
    if (lang === "AR") setDirection("rtl")
    else setDirection("ltr")
  }

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var countryurl = url.searchParams.get("cnt")
    var lang = url.searchParams.get("lang");
    var is_shisha = url.searchParams.get("isshisha");
    if(lang) setLanguage(lang)
    if(is_shisha) setIsShisha(is_shisha)
    var gen = ''

    switch (countryurl) {
      case "KSA":
        setCurrency(["SAR", "ريال"])
        gen = 49
        setGeneration(gen)
        break;
      case "awanidubaimarina":
        setCurrency(["AED", "درهم"])
        gen = 55
        setGeneration(gen)
        break;

      default:
        setCurrency(["AED", "درهم"])
        gen = 48
        setGeneration(gen)
        break;
    }

    fetch('https://qrportalbackend.azurewebsites.net/category/outlet/lvl1/' + gen)
      .then(response => response.json())
      .then(data => {
        dataRefCat.current = data
        setLoadingCategories(false)
      })
      .catch(error => console.log(error))

    fetch('https://qrportalbackend.azurewebsites.net/category/outlet/lvl2/' + gen)
      .then(response => response.json())
      .then(data1 => {
        dataRefSubCat.current = data1
        setLoadingSubCategories(false)
      })
      .catch(error => console.log(error))

    fetch('https://qrportalbackend.azurewebsites.net/menu/outlet/' + gen + '/1')
      .then(response => response.json())
      .then(data2 => {
        dataRefProducts.current = data2
        fetch('https://qrportalbackend.azurewebsites.net/menu/outlet/' + gen + '/2')
          .then(response => response.json())
          .then(data2Ar => {
            dataRefProductsAr.current = data2Ar
            setLoadingProducts(false)
            setActiveTab(dataRefCat.current[0].Id)
            const subcats = dataRefSubCat.current.filter(item => item.ParentCategoryID === dataRefCat.current[0].Id)
            setFilteredSubCategories(subcats);
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line
  }, []);

  function handleNav1Click(catId) {
    setActiveTab(catId)
    if (catId === 191){
      if(language === "AR") window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/Awani_ShishaMenu_Agg_Arb.pdf"
      else window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/Awani_ShishaMenu_Agg_Eng.pdf"
    } 
    if (catId === 217){
      if(language === "AR") window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/Awani_ShishaMenu_Agg_Arb.pdf"  // change "#" to arabic shisha menu for KSA
      else window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/Awani_ShishaMenu_Agg_Eng.pdf" // change "#" to english shisha menu for KSA
    } 
    if (catId === 715){
      if(language === "AR") window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/AwaniDMM_ShishaMenu_Agg_Arb.webp"  // change "#" to arabic shisha menu for KSA
      else window.location = "https://awjftp.blob.core.windows.net/awjcontents/websites/awani/AwaniDMM_ShishaMenu_Agg_Eng.webp" // change "#" to english shisha menu for KSA
    } 
    else {
      const subcats = dataRefSubCat.current.filter(item => item.ParentCategoryID === catId);
      setFilteredSubCategories(subcats);
    }
  }

  return (
    <div className="App">
      <BANNER handleLanguage={handleLanguage} language={language} direction={direction} />
      {!loadingProducts ? (<BODY activeTab={activeTab} direction={direction} categories={dataRefCat.current} loadingCategories={loadingCategories} subCategories={filteredSubCategories} loadingSubCategories={loadingSubCategories} products={dataRefProducts.current} productsAr={dataRefProductsAr.current} loadingProducts={loadingProducts} handleNav1Click={handleNav1Click} language={language} currency={currency} isShisha={isShisha}/>) : (<Placeholder as="p" animation="glow"><Placeholder className="vh-100" xs={12} size="lg" /></Placeholder>)}
    </div>
  );
}

export default App;
