import React, { useRef, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
function Navbar1({ activeTab, direction, categories, handleNav1Click, language, isShisha }) {

    const divRefN1 = useRef(null)

    useEffect(() => {
        const handleScroll = () => {

            if (divRefN1.current) {
                const { top } = divRefN1.current.getBoundingClientRect()
                // Check if the top of the div is at the top of the viewport
                if (top === 0) {
                    divRefN1.current.classList.remove('mx-3')
                    divRefN1.current.classList.remove('rounded-4')
                    //divRefN1.current.classList.add('alert-nav1')
                } else {
                    divRefN1.current.classList.add('mx-3')
                    divRefN1.current.classList.add('rounded-4')
                    //divRefN1.current.classList.remove('alert-nav1')
                }
                console.log(top)
            }
        }

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll)

        // Cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    function changetab(tabId) {
        window.scrollTo(0, 250)
        handleNav1Click(tabId)
    }

    function next() {
        const activeNavItem = document.querySelector(".active-nav1")

        var nextElement = activeNavItem.nextElementSibling

        if (nextElement) {
            activeNavItem.classList.remove('active-nav1')
            nextElement.classList.add('active-nav1')
            nextElement.click()
            document.getElementById(nextElement.id).scrollIntoView({ inline: "center" })
        }
    }

    function prev() {
        const activeNavItem = document.querySelector(".active-nav1")
        var prevElement = activeNavItem.previousElementSibling
        if (prevElement) {
            activeNavItem.classList.remove('active-nav1')
            prevElement.classList.add('active-nav1')
            prevElement.click()
            document.getElementById(prevElement.id).scrollIntoView({ inline: "center" })
        }
    }
    return (
        <Alert className='d-flex justify-content-between align-items-center primary-bg text-light text-uppercase text-center ff-domaine mx-3 border-0 rounded-4 p-1 my-2 sticky-top' ref={divRefN1}>
            <div><Image src="../assets/prev.png" className='nav-btn' onClick={direction === "ltr" ? prev : next} /></div>
            <Row className='d-flex align-items-center h-scroll mx-1 w-100 py-2 fs-nav1' dir={direction}>
                {
                    categories?.map((item, index) => {
                        let dirClass = ''
                        if (language === 'AR') dirClass = 'nav1-itemAr'
                        else dirClass = ''
                        if (item.Id === 191 && isShisha === 'false') return null;
                        else return <Col key={"navbar1-" + index} xs={4} id={"nav1-" + item.Id} onClick={() => changetab(item.Id)} className={activeTab === item.Id ? ('active-nav1 h-35 my-1 nav1-item ' + dirClass) : ('h-35 my-1 nav1-item ' + dirClass)}>{language === "AR" ? item.ArabicName : item.Name}</Col>
                    })
                }
            </Row>
            <div><Image src="../assets/next.png" className='nav-btn' onClick={direction === "ltr" ? next : prev} /></div>
        </Alert>
    );
}

export default Navbar1;